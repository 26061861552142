import { useNavigation } from "@remix-run/react";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";

import { Button } from "~/components/ui/button";
import { Spinner } from "~/components/ui/spinner";
import { usePagination } from "~/hooks/use-pagination";
import { cn } from "~/utils/classnames";
import { generatePaginationNumbers } from "~/utils/pagination";

import "./Pagination.css";
import type { PaginationProps } from "./Pagination.types";

export const Pagination = ({ totalItems, itemsPerPage }: PaginationProps) => {
  const navigation = useNavigation();

  const { currentPage, getPaginatedUrlWithCurrentParams, totalPages } = usePagination({
    totalItems,
    itemsPerPageFallback: itemsPerPage,
  });

  return (
    <div className="Pagination">
      {totalItems && totalItems > 1 && totalPages > 1 ? (
        <>
          {navigation.state !== "idle" ? (
            <Spinner className="Pagination-loadingSpinner" />
          ) : (
            <>
              {currentPage === 1 || currentPage < 1 || currentPage > totalPages ? null : (
                <Button
                  variant="ghost"
                  as="link"
                  className={cn(
                    "Pagination-button",
                    currentPage === 1 && "Pagination-button--hidden"
                  )}
                  to={currentPage === 1 ? "" : getPaginatedUrlWithCurrentParams(currentPage - 1)}
                  disabled={currentPage === 1}
                  aria-label="Page précédente"
                >
                  <RiArrowLeftSLine className="h-4 w-4" />
                </Button>
              )}
              {generatePaginationNumbers(currentPage, totalPages).map((page, index) => (
                <Button
                  key={index}
                  variant="ghost"
                  as="link"
                  className={cn(
                    "Pagination-button",
                    currentPage === page && "Pagination-button--active"
                  )}
                  to={page !== "..." ? getPaginatedUrlWithCurrentParams(Number(page)) : ""}
                  disabled={currentPage === page || page === "..."}
                >
                  {page}
                </Button>
              ))}
              {currentPage === totalPages || currentPage > totalPages ? null : (
                <Button
                  variant="ghost"
                  as="link"
                  className={cn(
                    "Pagination-button",
                    currentPage === totalPages && "Pagination-button--hidden"
                  )}
                  to={
                    currentPage === totalPages
                      ? ""
                      : getPaginatedUrlWithCurrentParams(currentPage + 1)
                  }
                  disabled={currentPage === totalPages}
                  aria-label="Page suivante"
                >
                  <RiArrowRightSLine className="h-4 w-4" />
                </Button>
              )}
            </>
          )}
        </>
      ) : null}
    </div>
  );
};
